
@font-face {
  font-family: Lao;
  src: url(Defago_noto.ttf)format('truetype');
  /* font-style: normal; */
  font-weight: 600;
}
*{
  font-family:Lao!important;
}
body {
  margin: 0;
  /* font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,Lao; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa, .far, .fas{
  font-family: "Font Awesome 5 Free" !important;
}
.fab {
  font-family: "Font Awesome 5 Brands" !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h4{
  margin-bottom: 12px;
}
